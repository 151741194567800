.wm {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1; }

.wm-food-menu::before {
  display: block;
  content: url(food-menu.svg);
  width: 20px;
  height: 18px;
}

.wm-prato::before {
  display: block;
  content: url(novo-prato.svg);
  width: 23px;
  height: 18px;
}
