.invoice-summary {
  width: 220px;
  text-align: right;
  float: right;
  &.invoice-summary-input {
    width: 320px;
    p,
    h5 {
      span {
        width: 180px;
      }
    }
  }
  p,
  h5 {
    display: flex;
    justify-content: flex-end;
    span {
      width: 120px;
      .small-input {
        display: inline-block;
        width: 3rem;
        margin: 0 0.75rem;
      }
    }
  }
}

@media print {
  body * {
    visibility: hidden;
  }
  #main {
    position: relative;
    padding: 0;
    height: 1px;
    overflow: visible;
  }
  .main-content-wrap {
    width: 100% !important;
  }
  #print-area,
  #print-area * {
    visibility: visible;
  }
  #print-area {
    position: absolute;
    left: 0;
    top: -20mm;
    width: 100%;
    padding: 0;
    margin: 0px;
  }
}

[dir="rtl"] {
  .invoice-summary {
    text-align: left !important;
  }
}
